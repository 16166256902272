<template>
  <div>
    <Loading v-if="loading" />
    <div v-else style="height: 125px">
      <div style="display: flex; height: 125px">
        <div class="user-welcome">
          Hello, {{ user.name ? user.name : "you" }}!
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin: 100px;
          margin-left: 60px;
        "
      ></div>
    </div>
    <div style="display: flex">
      <div
        style="
          display: flex;
          justify-content: flex-start;
          text-align: center;
          flex-wrap: wrap;
          width: 66%;
          margin: 0 5%;
          border-radius: 10px;
          padding: 2% 0% 5% 0%;
        "
        class="users-container"
      >
        <div style="width: 100%; display: flex; justify-content: center">
          <div style="width: 500px">
            <v-text-field
              v-model="filterString"
              label="Filter by name"
            ></v-text-field>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-height: 60vh;
            overflow-y: auto;
            width: 100%;
          "
        >
          <div v-for="(user, index) in filteredUsers" :key="index">
            <v-card
              v-if="user"
              class="user-card"
              @click="viewUserAssessments(user.id)"
              style="
                min-height: 250px;
                max-height: 250px;
                width: 250px;
                margin: 10px;
              "
            >
              <v-card-title style="display: flex; justify-content: center">
                {{ user.name }}
              </v-card-title>
              <div
                class="avatar"
                style="margin-bottom: 10px"
                :style="`background-color: ${
                  getUserCompletedAssessmentCount(user.id) === 0
                    ? '#5b616a'
                    : '#6bc494'
                };`"
              >
                <div class="avatar__letters">
                  {{
                    user.name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")
                  }}
                </div>
              </div>
              <v-card-text>
                {{ user.hireDate ? user.hireDate : "No hire date given" }}
                <br />
                {{ getUserCompletedAssessmentCountString(user.id) }}<br />
                {{ getUserPendingAssessmentCount(user.id) }}
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 33%;
          margin-right: 8%;
          flex-direction: column;
        "
      >
        <v-btn
          color="#6bc494"
          style="color: white; margin: 30px"
          elevation="2"
          @click="toggleModal"
        >
          Create an assessment request
        </v-btn>
        <div style="margin-bottom: 10px; text-align: center">
          Pending Assessments
        </div>
        <div>
          <div v-for="assessment in pendingAssessments" :key="assessment.id">
            <v-alert
              @input="cancelAssessmentRequest(assessment.id)"
              border="left"
              color="secondary"
              dismissible
              outlined
              style="text-align: center"
            >
              <div v-if="assessment.assessedId !== assessment.assessorId">
                <b>{{ formatUserName(assessment.assessedId) }}</b> assessed by
                {{ formatUserName(assessment.assessorId) }}
              </div>
              <div v-else>
                <b>{{ formatUserName(assessment.assessedId) }}</b> Self
                Assessment
              </div>
            </v-alert>
          </div>
          <!-- <v-btn @click="uploadAssessmentTemplate">Upload</v-btn> -->
        </div>
      </div>
    </div>
    <SendAssessmentRequestModal
      v-if="!this.loading"
      :users="users"
      :show-modal="showModal"
      :isAdmin="true"
      :userId="selectedUserId"
      @reload-assessment-requests="loadAssessments"
      @toggle-modal="toggleModal"
    />
  </div>
</template>

<script>
import "firebase/compat/auth";
import { getAllUsers } from "../../api/endpoints/user";
import {
  getAllAssessments,
  cancelAssessment,
  uploadTemplate,
} from "../../api/endpoints/assessment";
import SendAssessmentRequestModal from "./SendAssessmentRequestModal.vue";
import Loading from "./Loading.vue";

export default {
  components: {
    SendAssessmentRequestModal,
    Loading,
  },
  data() {
    return {
      loading: true,
      filterString: "",
      users: [],
      user: null,
      showModal: false,
      selectedUserId: null,
      assessments: [],
    };
  },
  computed: {
    filteredUsers() {
      if (this.filterString.length > 0) {
        return this.users.filter((u) =>
          u.name.toLowerCase().includes(this.filterString.toLowerCase())
        );
      } else {
        return this.users;
      }
    },
    pendingAssessments() {
      return this.assessments.filter(
        (a) => !a.dateCompleted && !a.dateCanceled
      );
    },
  },
  methods: {
    async init() {
      const users = await getAllUsers();
      this.users = users.sort((a, b) => a.name.localeCompare(b.name));
      this.user = this.users.find(
        (user) => user.id === this.$route.query.userId
      );
      await this.loadAssessments();
      this.loading = false;
    },
    async loadAssessments() {
      this.assessments = await getAllAssessments();
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    getUserCompletedAssessmentCount(userId) {
      const count = this.assessments.filter(
        (a) => a.assessedId === userId && a.dateCompleted
      ).length;

      return count;
    },
    getUserCompletedAssessmentCountString(userId) {
      const count = this.getUserCompletedAssessmentCount(userId);

      return `${count} ${
        count === 1 ? " completed assessment" : "completed assessments"
      }`;
    },
    getUserPendingAssessmentCount(userId) {
      const count = this.assessments.filter(
        (a) => a.assessedId === userId && !a.dateCompleted && !a.dateCanceled
      ).length;

      return `${count} ${
        count === 1 ? "pending assessment" : "pending assessments"
      }`;
    },
    async viewUserAssessments(id) {
      if (this.getUserCompletedAssessmentCount(id) === 0) {
        return;
      }
      this.$router.push({
        name: "assessmentOverview",
        query: { userId: id, adminId: this.user.id },
      });
    },
    formatUserName(id) {
      if (id) {
        return this.users.find((user) => user.id === id).name;
      } else {
        return "";
      }
    },
    async cancelAssessmentRequest(id) {
      try {
        await cancelAssessment(id);
        await this.loadAssessments();
      } catch (e) {
        console.log(e);
      }
    },
    uploadAssessmentTemplate() {
      uploadTemplate();
    },
    openDashboard() {
      this.$router.push({ name: "dashboard", query: { userId: this.user.id } });
    },
  },
  async created() {
    await this.init();
  },
};
</script>
<style scoped>
.user-card {
  min-width: 20rem;
  max-width: 20rem;
  margin-left: 30px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
}

.avatar {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #aad2da;
  color: #fff;
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.avatar__letters {
  /* Center the content */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
}
.user-welcome {
  display: flex;
  justify-content: center;
  margin: 25px;
  margin-left: 45%;
  font-size: 1.5rem;
}
.users-container {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
</style>
